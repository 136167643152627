import { Typography } from "@mui/material";
import React from "react";
import { CancellationHeaderWrapper } from "./style";

const CancellationHeader: React.FC = () => {
  return (
    <CancellationHeaderWrapper>
      <Typography className="cancellation-label">Cancel Booking</Typography>
    </CancellationHeaderWrapper>
  );
};

export default CancellationHeader;
