import React from "react";
import BookingDetails from ".";
import { extractBookingDetails } from "../../../Utils/extract";

interface IProps {
  searchParams: URLSearchParams;
}
const ConfirmationBookingDetails: React.FC<IProps> = ({ searchParams }) => {
  const {
    companyName,
    locationName,
    serviceName,
    date,
    startTime,
    duration,
    bookingId,
    quantity,
  } = extractBookingDetails(searchParams);

  const lastRow = quantity
    ? [
        { label: "BookingId", value: bookingId! },
        { label: "quantity", value: quantity },
      ]
    : [{ label: "BookingId", value: bookingId! }];

  return (
    <>
      <BookingDetails
        details={[
          {
            items: [
              { label: "Company", value: companyName! },
              { label: "Location", value: locationName! },
            ],
          },
          {
            items: [
              { label: "Date", value: date },
              { label: "Service", value: serviceName! },
            ],
          },
          {
            items: [
              { label: "Start Time", value: startTime },
              { label: "Duration", value: duration },
            ],
          },
          {
            items: lastRow,
          },
        ]}
      />
    </>
  );
};

export default ConfirmationBookingDetails;
